

















































































import { Vue, Component } from "vue-property-decorator";
import Quill from "quill";
import { VueEditor } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";
import axios, { AxiosRequestHeaders } from "axios";
import { ActivityGroup } from "@/types";

Quill.register("modules/htmlEditButton", htmlEditButton);

@Component({
  metaInfo() {
    return {
      title: "Oluştur / Faaliyet / Admin"
    };
  },
  components: {
    VueEditor
  }
})
export default class AdminActivityCreateView extends Vue {
  form = {
    groupId: "",
    title: "",
    urlCode: "",
    points: 0,
    description: null as string | null
  };
  submitDisabled = false;
  editorOptions = {
    modules: {
      htmlEditButton: {
        syntax: true
      }
    }
  };
  activityGroups = [] as ActivityGroup[];

  get requiredRule(): unknown[] {
    return [(v: unknown) => !!v || "Zorunlu alan"];
  }

  resetForm() {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();

    this.form.description = "";
    this.resetValidation();
  }

  resetValidation() {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  async getActivityGroups() {
    try {
      var res = await axios.get("/api/activitygroup/get-all", {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      this.activityGroups = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Faaliyet grupları alınamadı.`
      });
    }
  }

  async create() {
    const form = this.$refs.form as HTMLFormElement;

    if (!form.validate()) {
      return;
    }

    this.submitDisabled = true;

    try {
      const res = await axios.post("/api/activity/create",
        {
          Title: this.form.title,
          Description: this.form.description,
          Points: this.form.points,
          GroupId: this.form.groupId,
          UrlCode: this.form.urlCode
        },
        {
          headers: this.$store.getters["auth/requestAuthHeader"]
        });

        this.$notify({ type: "success", text: "Faaliyet başarıyla oluşturuldu. Lütfen bekleyiniz..." });

        const timer1 = setTimeout(() => {
          this.$router.push({ name: "AdminActivities" });

          clearTimeout(timer1);
        }, 3000);
    } catch (e) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Faaliyet oluşturulamadı.`
      });

      throw e;
    } finally {
      this.submitDisabled = false;
    }
  }

  async mounted() {
    await this.getActivityGroups();
  }
}
